import React, { useState } from 'react';
import './navBar.scss';
import logo from '../provisional_icons/Logo-RLS.png';

export function NavBar() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const updateSideBar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <>
            <div className="banner">
                <div className='inner-container'>
                    <div className={"logged-in-company"}>
                        <img src={logo} alt='logo' onClick={() => updateSideBar} />
                    </div>
                </div>
            </div>
        </>
    );
}
