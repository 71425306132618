import axios from 'axios';
import './uploadFile.scss';
import React from 'react';
import { useState } from "react";
import Papa from "papaparse";
import 'font-awesome/css/font-awesome.min.css';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import DownloadIcon from '@mui/icons-material/Download';
import {styled} from '@mui/material/styles';
import LoadingSpinner from './loadSpinner';

// Download Options for the user 
const options = ['Download Results', 'Download Similar Trials Data', 'Download Relevant Similar Trials Data'];

// Styling MUI Button
const StyledButton = styled(Button)`
background-color : var(--primary);
color:var(--lightGrayishBlue3);
padding: 15px 15px;
text-align: center;
display: inline-block;
font-size: 12px;
border-radius: 5px;
font: var(--primaryFont);
'&:hover': {
  background-color:var(--secondary);,
}
`;



function UploadFile() {
  // Upload Component 

  // State to indicate dropdown open action
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  // State to get the dropdown selected index
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    // Handle Click on the DropDown
    if(options[selectedIndex] == 'Download Results'){
      exportSimilarTrials(parsedData,options[selectedIndex].split(" ")[1])
    }
    else if(options[selectedIndex] == 'Download Similar Trials Data'){
      exportSimilarTrials(similarTrialRows,options[selectedIndex])
    }
    else{
      exportSimilarTrials(relevantTrialRows,options[selectedIndex])
    }
  };

  const handleMenuItemClick = (event, index) => {
    // Handle Selection of Item
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    // Handle Toggle of DropDown 
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // Handle Close of DropDown Menu 
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

    // State to Store the Meta Data of the given Trials 
	  const [parsedData, setParsedData] = useState([]);

    // State to Store Metadata of Similar Trials 
    const [similarTrialData, setSimilarTrialData] = useState(new Map());

    // State to view the download button 
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    
    // State to give all the Metadata of the similar Trials 
    const [similarTrialRows, setSimilarTrialRows] = useState(new Map());

    // State to give all the metadata of the Trials relevant to given data 
    const [relevantTrialRows, setRelevantTrialRows] = useState(new Map());
    

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the table values
  const [values, setValues] = useState([]);

  const fileExtension = '.xlsx';

  // URL constants 
  const Trial_url = "https://ClinicalTrials.gov/api/query/study_fields?expr=";
  const url_fields = "&fields=BriefTitle,Condition,StartDate,CompletionDate,EnrollmentCount,LocationCountry,Gender,MinimumAge,MaximumAge,StdAge,ConditionBrowseLeafName"  
  const Trial_json_expr = "&fmt=json";
  const Trial_min_expr = "&min_rnk=";
  const Trial_max_expr = "&max_rnk=";

  var Trial_id = "";

  function checkCounter(final_length,processed_data){

    // Function to store all data in the final iteration 
      if(final_length === processed_data.length){}
        var tableRowsArray = [];
        var tableValuesArray = [];
        
        processed_data.map(d => {
            tableRowsArray.push(Object.keys(d));
            tableValuesArray.push(Object.values(d));
        });

      // Filtered Column Names
      setTableRows(tableRowsArray[0]);

      // Filtered Values
      setValues(tableValuesArray);
    
      setParsedData({"result":processed_data});
      
}

function validateTrial(Trial_data,min_age,max_age,start_date,end_date,country_list_array){
  // Function to verify if the Trial is similar to the given Trial 

  // Age Validation 
  var similar_Trial_min_age = 0
  if(Trial_data["MinimumAge"].length != 0){
    if(Trial_data["MinimumAge"][0].split(" ")[1] == "Month" || Trial_data["MinimumAge"][0].split(" ")[1] == "Months"){
      similar_Trial_min_age = parseInt(Trial_data["MinimumAge"][0].split(" ")[0]) / 12;
    }
    else{
      similar_Trial_min_age = parseInt(Trial_data["MinimumAge"][0].split(" ")[0])
    } 
  }

  var Trial_min_age = 0
  if(min_age.length != 0){
    if(min_age[0].split(" ")[1] == "Month" || min_age[0].split(" ")[1] == "Months"){
      Trial_min_age = parseInt(min_age[0].split(" ")[0]) / 12;
    }
    else{
      Trial_min_age = parseInt(min_age[0].split(" ")[0])
    } 
  }

  var Trial_max_age = 100
  if(max_age.length != 0){
    if(max_age[0].split(" ")[1] == "Month" || max_age[0].split(" ")[1] == "Months"){
      Trial_max_age = parseInt(max_age[0].split(" ")[0]) / 12;
    }
    else{
      Trial_max_age = parseInt(max_age[0].split(" ")[0])
    } 
  }

  if(similar_Trial_min_age > 0 & Trial_min_age > 0){
    if(similar_Trial_min_age < Trial_min_age){
      return false
    }
  }
  else if(similar_Trial_min_age == 0 ){
    return false
  }
  
  if(similar_Trial_min_age > 0 & Trial_max_age > 0){
    if(similar_Trial_min_age > Trial_max_age){
      return false
    }
  }
  
  //Date Validation

  if(Trial_data["StartDate"].length & start_date.length & end_date.length){
    var similar_start_date = new Date(Trial_data["StartDate"])
    var Trial_start_date = new Date(start_date)
    var Trial_end_date = new Date(end_date)
    
    if(Trial_start_date>similar_start_date || similar_start_date > Trial_end_date){
        
        return false
    }
  }
  
  // Country Validation
  if(country_list_array.length>0 && Trial_data["LocationCountry"].length>0){
    var match = 0
    for(var ind in country_list_array){
      if(Trial_data["LocationCountry"].includes(country_list_array[ind])){
        match = 1
      }
    }
    if(match == 0){
      return false
    }
  }
  else if(Trial_data["LocationCountry"].length == 0){
      return false
  }
  

  
  
  return true
}

function findSimilarTrials(Trial_study_title,expression,total_number_of_reports,min_age,max_age,start_date,end_date,country_list_array,isTrialIndication){
    // Function to find all the similar Trials to given expression 

    var index = 1
    var total_population = 0
    
    
    console.log(expression)
    for(var i=index;i<total_number_of_reports;i+=1000){
     axios.get(Trial_url+expression+url_fields+Trial_min_expr+i+Trial_max_expr+(i+999)+Trial_json_expr)
            .then(similar_Trial_data => { 
              var similar_Trial_studies = similar_Trial_data.data.StudyFieldsResponse.StudyFields
              
                for(var similar_studies_index in similar_Trial_studies){
                  if(validateTrial(similar_Trial_studies[similar_studies_index],min_age,max_age,start_date,end_date,country_list_array)&&similar_Trial_studies[similar_studies_index]["EnrollmentCount"][0]){
                    
                    total_population = similarTrialData.get(Trial_study_title).get(isTrialIndication) + parseInt(similar_Trial_studies[similar_studies_index]["EnrollmentCount"][0]);
                    setSimilarTrialData(similarTrialData.get(Trial_study_title).set(isTrialIndication,total_population));
                    if(isTrialIndication){
                      var similar_Trial_list = similarTrialRows.get(Trial_study_title)
                      similar_Trial_list.push(similar_Trial_studies[similar_studies_index])
                      setSimilarTrialRows(similarTrialRows => ({...similarTrialRows,
                                                                [Trial_study_title]:[...similar_Trial_list,]
                                                              }))
                    }
                    else{
                      var relevant_Trial_list = relevantTrialRows.get(Trial_study_title)
                      relevant_Trial_list.push(similar_Trial_studies[similar_studies_index])
                      setRelevantTrialRows(relevantTrialRows => ({...relevantTrialRows,
                                                                [Trial_study_title]:[...relevant_Trial_list,]
                                                              }))
                    }
                    
                    
                  }
                }

    })}
    return total_population
}

const clickHandler = (event) => {
  // Handle Click helps when same file is uploaded twice  
  event.target.value = ''
}
  const changeHandler = (event) => {
    // File upload handling 
    setParsedData([]);
    setSimilarTrialData(new Map());
    setShowDownloadButton(false);
    setSimilarTrialRows(new Map());
    setRelevantTrialRows(new Map());
    document.getElementById("processFile").disabled = false;

    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        // Parsed Data Response in array format
        setParsedData(results.data);

        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        setValues(valuesArray);
        
      },
    });
    console.log(showDownloadButton)
  };

  const processFile= (event) => {
      // Processing Uploaded CSV file to get the similar Trial metadata 
      setIsLoading(true);   
      document.getElementById("processFile").disabled = true;
      document.getElementById("inputfile").disabled = true;
    
      const processedData = [];
      for(var x in parsedData){
        Trial_id = parsedData[x]["Trial Number/Name"].split(':')[0].replace("-","%2D")
        console.log()
        axios.get(Trial_url+Trial_id+url_fields+Trial_min_expr+"1"+Trial_max_expr+"4"+Trial_json_expr)
        .then(res => {
            processedData.push({})
            const index = processedData.length-1

            if(res.data.StudyFieldsResponse.NStudiesFound > 0){
                const study_metadata = res.data.StudyFieldsResponse.StudyFields[0];
                processedData[index]["Trial Expression"] = res.data.StudyFieldsResponse.Expression;
                processedData[index]["Study Title"] = study_metadata["BriefTitle"];
                processedData[index]["Trial Indication"] = "Condition" in study_metadata ? study_metadata["Condition"].join(", ") : "";
                processedData[index]["Start Date"] = study_metadata["StartDate"];
                processedData[index]["End Date"] = study_metadata["CompletionDate"];
                processedData[index]["No Of Participants"] = study_metadata["EnrollmentCount"];
                var country_list = new Set();
                for(var loc in study_metadata["LocationCountry"]){
                    country_list.add(study_metadata["LocationCountry"][loc])
                }
                const country_list_array = Array.from(country_list);
                processedData[index]["Location"] = country_list_array.join(",")
                processedData[index]["Gender"] = study_metadata["Gender"]
                processedData[index]["Min Age"] = study_metadata["MinimumAge"]
                processedData[index]["Max Age"] = study_metadata["MaximumAge"]
                processedData[index]["Standard Age"] = study_metadata["StdAgeList"]
                processedData[index]["Similar Trial Data Population"] = 0
                processedData[index]["Similar Trial Data Population with Additional Trial Indications"] = 0
                setSimilarTrialData(similarTrialData.set(processedData[index]["Study Title"],new Map()));
                setSimilarTrialData(similarTrialData.get(processedData[index]["Study Title"]).set(true,0));
                setSimilarTrialData(similarTrialData.get(processedData[index]["Study Title"]).set(false,0));
                setSimilarTrialRows(similarTrialRows.set(processedData[index]["Study Title"],[]));
                setRelevantTrialRows(relevantTrialRows.set(processedData[index]["Study Title"],[]));
                // Similar Trials for Trial indication
                var conditional_array_join = study_metadata["Condition"].join(" OR ")
                
                var conditional_array_join_2 = study_metadata["ConditionBrowseLeafName"].join(" OR ")
                  axios.get(Trial_url+conditional_array_join.replace("-","%2D")+url_fields+Trial_json_expr)
                          .then(result => { 
                              
                              var no_of_similar_reports = result.data.StudyFieldsResponse.NStudiesFound
                              
                              findSimilarTrials(processedData[index]["Study Title"],conditional_array_join.replace("-","%2D"),no_of_similar_reports,processedData[index]["Min Age"],processedData[index]["Max Age"],processedData[index]["Start Date"],processedData[index]["End Date"],country_list_array,true)
                              
                              
                              
                          })
                          setTimeout(function() {
                            console.log(similarTrialData.get(processedData[index]["Study Title"]))
                        }.bind(this), 5000)  
                      
                        setTimeout(function() {
                          
                          processedData[index]["Similar Trial Data Population"] = similarTrialData.get(processedData[index]["Study Title"]).get(true)
                          setShowDownloadButton(true);
                      }.bind(this), 5000)
                
              // Similar Trials for similar Trial indication
              
                  axios.get(Trial_url+conditional_array_join_2.replace("-","%2D")+url_fields+Trial_json_expr)
                          .then(result => { 
                              
                              var no_of_similar_reports = result.data.StudyFieldsResponse.NStudiesFound
                              findSimilarTrials(processedData[index]["Study Title"],conditional_array_join_2.replace("-","%2D"),no_of_similar_reports,processedData[index]["Min Age"],processedData[index]["Max Age"],processedData[index]["Start Date"],processedData[index]["End Date"],country_list_array,false)
                              
                              
                              
                          })
                          
                      
                        setTimeout(function() {
                          
                          processedData[index]["Similar Trial Data Population with Additional Trial Indications"] = similarTrialData.get(processedData[index]["Study Title"]).get(false)
                          
                      }.bind(this), 10000)
                  
                

            }
            setTimeout(function() { //Start the timer
              checkCounter(parsedData.length,processedData)
              setShowDownloadButton(true);
              setIsLoading(false);
              document.getElementById("inputfile").disabled = false;
    
              
          }.bind(this), 10000)
            
        })
        


      }
      
  }

  const exportSimilarTrials = (downloadTrialRows,filename_suffix) => {
  //  Function to download given files 

   var wb = XLSX.utils.book_new()
   for(var similar_Trial_index in downloadTrialRows){
     for(let index in downloadTrialRows[similar_Trial_index]){
       downloadTrialRows[similar_Trial_index][index]["BriefTitle"] = "BriefTitle" in downloadTrialRows[similar_Trial_index][index] ? downloadTrialRows[similar_Trial_index][index]["BriefTitle"] : "";
      downloadTrialRows[similar_Trial_index][index]["Condition"] = "Condition" in downloadTrialRows[similar_Trial_index][index] ? downloadTrialRows[similar_Trial_index][index]["Condition"].join(", ") : "";
      downloadTrialRows[similar_Trial_index][index]["StartDate"] = "StartDate" in downloadTrialRows[similar_Trial_index][index] ? downloadTrialRows[similar_Trial_index][index]["StartDate"][0] : "";
      downloadTrialRows[similar_Trial_index][index]["CompletionDate"] = "CompletionDate" in downloadTrialRows[similar_Trial_index][index] ? downloadTrialRows[similar_Trial_index][index]["CompletionDate"][0] : "";
      downloadTrialRows[similar_Trial_index][index]["EnrollmentCount"] = "EnrollmentCount" in downloadTrialRows[similar_Trial_index][index] ? downloadTrialRows[similar_Trial_index][index]["EnrollmentCount"][0] : "";
      var country_list = new Set();
      for(var loc in downloadTrialRows[similar_Trial_index][index]["LocationCountry"]){
          country_list.add(downloadTrialRows[similar_Trial_index][index]["LocationCountry"][loc])
      }
      const country_list_array = Array.from(country_list);
      downloadTrialRows[similar_Trial_index][index]["LocationCountry"] = country_list_array.join(",")
      downloadTrialRows[similar_Trial_index][index]["Gender"] = "Gender" in downloadTrialRows[similar_Trial_index][index] ? downloadTrialRows[similar_Trial_index][index]["Gender"][0] : "";
      downloadTrialRows[similar_Trial_index][index]["MinimumAge"] = "MinimumAge" in downloadTrialRows[similar_Trial_index][index] ? downloadTrialRows[similar_Trial_index][index]["MinimumAge"][0] : "";
      downloadTrialRows[similar_Trial_index][index]["MaximumAge"] = "MaximumAge" in downloadTrialRows[similar_Trial_index][index] ? downloadTrialRows[similar_Trial_index][index]["MaximumAge"][0] : "";
      downloadTrialRows[similar_Trial_index][index]["StdAgeList"] = "StdAgeList" in downloadTrialRows[similar_Trial_index][index] ? downloadTrialRows[similar_Trial_index][index]["StdAgeList"].join(", ") : "";
    
     }
      
     var ws = XLSX.utils.json_to_sheet(downloadTrialRows[similar_Trial_index]);
     
     XLSX.utils.book_append_sheet(wb,ws,similar_Trial_index.substring(0,15));
   }
   
   XLSX.writeFile(wb,filename_suffix+"_Similar_Trials_Results" + fileExtension)
   
 }


  
  return (
    <div>
      {/* File Uploader */}
      <div> Similar Trial Data</div>
      <input
        type="file"
        name="file"
        id="inputfile"
        className="inputfile"
        onClick={clickHandler}
        onChange={changeHandler}
        accept=".csv"
        />
      <br />
      <br/>

      {/* Process File Button  */}
      <button id="processFile" onClick={processFile} className="processFile">
            Process File
    </button>
        <br />
      <br />

      {/* Dropdown Buttons for downloading */}
      {showDownloadButton ? 
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <StyledButton className="DownloadButton" onClick={handleClick}><DownloadIcon/> {options[selectedIndex]}</StyledButton>
        <StyledButton
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </StyledButton>
      </ButtonGroup>: ""}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {/* Series Buttons for downloading  */}
      {/* {showDownloadButton ? 
      <CSVLink data={parsedData} filename="clinicalTrials_data.csv" target="_blank" className="fa fa-download downloadButton"> Download Results</CSVLink>
      : ""}
      {showDownloadButton ? 
      <button variant="warning" className="fa fa-download similarTrialsDownloadButton" onClick={(e) => exportSimilarTrials(similarTrialRows)}> Download Similar Trials Data</button>
       :""}
      {showDownloadButton ? 
      <button variant="warning" className="fa fa-download similarTrialsDownloadButton" onClick={(e) => exportSimilarTrials(relevantTrialRows)}> Download Relevant Trial Data</button>
       :""} */}
      
      {/* Table */}
      {isLoading ? <LoadingSpinner /> : 
      <div className="mt-2 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"></div>
            
      <table className="min-w-full divide-y divide-gray-200 table-responsive table-hover">
        <thead className="bg-gray-50">
          <tr>
            {tableRows.map((rows, index) => {
              return <th scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              key={index}>{rows}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {values.map((value, index) => {
            return (
              <tr key={index}>
                {value.map((val, i) => {
                  return <td key={i}>{val}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      
      </div>
      </div>
      </div>}
    </div>
  );
}

export default UploadFile;
