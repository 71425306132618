import './App.css';
import { NavBar } from './components/navbar';
import UploadFile from './components/uploadFile';
import { Footer } from './components/footer';

function App() {
  return (
    <div className = "App">
      <NavBar></NavBar>
      <UploadFile></UploadFile>
      <div style = {{ display: "flex", flexGrow: 1 }} />
      <Footer />
    </div>
  );
}

export default App;
